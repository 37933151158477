import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const Leadership = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Management Team - KATSANA® Integrated Fleet Management & Industrial
          Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.katsana.com/leadership/" />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.katsana.com/leadership/" />
        <meta
          property="og:title"
          content="Management Team - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 text-lg md:pt-12 md:text-2xl text-bluegray">
            The Leadership
          </h3>
          <h1 className="pt-4 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            A mission to modernize
            <br />
            enterprise operations.
          </h1>
        </div>
      </div>

      <div className=" bg-[hsla(204,26%,93%,1)] relative">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh]  "
            }
          ></div>
        </div>
        {/* Management Team */}
        <div
          id="management"
          className="max-w-6xl px-8 py-6 mx-auto xl:px-0 md:pt-24 text-bluetext"
        >
          <h5 className="text-3xl font-bold md:text-4xl">Management Team</h5>
        </div>
        <div className="max-w-6xl px-8 py-8 mx-auto xl:px-0 md:py-8">
          <div className="grid grid-cols-4 gap-6 text-sm xl:p-4 md:grid-cols-10 md:gap-12 text-bluetext">
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/fuqaha.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="text-base font-medium ">Syed Ahmad Fuqaha</h5>
                <span>Group CEO</span>
              </div>
              <p className="mt-2">KATSANA Holdings Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/irwan.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="text-base font-medium ">Mohd Irwan Ibrahim</h5>
                <span>Group COO</span>
              </div>
              <p className="mt-2">KATSANA Holdings Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/syafiq.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="text-base font-medium ">Ahmad Syafiq Noh</h5>
                <span>CEO</span>
              </div>
              <p className="mt-2">KATSANA Fleet Services Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/fahmi.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="font-medium ">Muhamad Fahmi</h5>
                <span>Head, Operation & administration</span>
              </div>
              <p className="mt-2">KATSANA Fleet Services Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/annawi.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="font-medium ">Mohamad Annawi</h5>
                <span>Head, Business Management</span>
              </div>
              <p className="mt-2">KATSANA Fleet Services Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/heety.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="font-medium ">Sharifah Heety</h5>
                <span>HR & Administration</span>
              </div>
              <p className="mt-2">KATSANA Holdings Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/justin.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="font-medium ">Ahmad Justin Yeo</h5>
                <span>Head, Customer Support</span>
              </div>
              <p className="mt-2">KATSANA Fleet Services Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/hanif.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="font-medium ">Mohd Hanif Ibrahim</h5>
                <span>Head, Engineering</span>
              </div>
              <p className="mt-2">KATSANA Fleet Services Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/thanusha.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="font-medium ">Thanusha Lingeswaran</h5>
                <span>Head, Marketing</span>
              </div>
              <p className="mt-2">KATSANA Fleet Services Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/fazri.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="font-medium ">Muhamad Fazri</h5>
                <span>Head, Customer Success</span>
              </div>
              <p className="mt-2">KATSANA Fleet Services Sdn Bhd</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/syam.jpg"
                objectFit="contain"
                className="rounded-lg"
              />
              <div className="h-20 mt-6">
                <h5 className="font-medium ">Syamsul Khairi Hasan</h5>
                <span>Head, Infrastructure</span>
              </div>
              <p className="mt-2">KATSANA Holding Sdn Bhd</p>
            </div>
          </div>
        </div>
        {/* Board of Directors */}
        <div
          id="directors"
          className="max-w-6xl px-8 mx-auto mt-16 xl:px-0 text-bluetext"
        >
          <h5 className="text-3xl font-bold md:text-4xl">Board of Directors</h5>
        </div>
        <div className="max-w-6xl px-8 py-16 mx-auto xl:px-0">
          <div className="grid grid-cols-5 gap-6 text-sm md:grid-cols-10 md:gap-12 xl:p-4 text-bluetext">
            <div className="col-span-5">
              <div className="flex">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/fuqaha.jpg"
                  objectFit="contain"
                  className="w-1/3 rounded-lg "
                />
                <div className="ml-4 md:ml-12">
                  <h5 className="text-base font-medium ">Syed Ahmad Fuqaha</h5>
                  <span>Director</span>
                  <p className="mt-8">KATSANA Holdings Sdn Bhd</p>
                </div>
              </div>
            </div>
            <div className="col-span-5">
              <div className="flex">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/irwan.jpg"
                  objectFit="contain"
                  className="w-1/3 rounded-lg"
                />
                <div className="ml-4 md:ml-12">
                  <h5 className="text-base font-medium ">Mohd Irwan Ibrahim</h5>
                  <span>Director</span>
                  <p className="mt-8">
                    KATSANA Holdings Sdn Bhd
                    <br />
                    KATSANA Fleet Services Sdn Bhd
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-5">
              <div className="flex">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/amin.jpg"
                  objectFit="contain"
                  className="w-1/3 rounded-lg"
                />
                <div className="ml-4 md:ml-12">
                  <h5 className="text-base font-medium ">Amin Shafie</h5>
                  <span>Director</span>
                  <p className="mt-8">
                    KATSANA Holdings Sdn Bhd
                    <br />
                    KATSANA Fleet Services Sdn Bhd
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-5">
              <div className="flex">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/chang.jpg"
                  objectFit="contain"
                  className="w-1/3 rounded-lg"
                />
                <div className="ml-4 md:ml-12">
                  <h5 className="text-base font-medium ">Chang Chew Soon</h5>
                  <span>Director</span>
                  <p className="mt-8">KATSANA Holdings Sdn Bhd</p>
                </div>
              </div>
            </div>
            <div className="col-span-5">
              <div className="flex">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/syafiq.jpg"
                  objectFit="contain"
                  className="w-1/3 rounded-lg"
                />
                <div className="ml-4 md:ml-12">
                  <h5 className="text-base font-medium ">Ahmad Syafiq Noh</h5>
                  <span>Director</span>
                  <p className="mt-8">KATSANA Fleet Services Sdn Bhd</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-56 md:h-[40vh] xl:h-[60vh]">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/thekatsana.jpg"
          objectFit="cover"
          className="!absolute inset-0 z-10"
        />
      </div>
      <Cta />
    </Layout>
  );
};

export default Leadership;
